#hero {
  background-image: url("https://inorinotani.com/images/bg_2.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin: 0;
  position:relative;
  gap:60px;


  .hero_left{
    width:30%;
    height:84vh;
    justify-content: space-between;
    img{
      width:85%;
      margin:0 auto;
    }
  }

  .hero_center{
    width:20%;
    max-width:70px;
    img{
      width:100%;
    }
  }

  .hero_right{
    width:30%;
    height:84vh;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap:10px;
    img {
      width:100%;
    }

    .news_list{
      order:1;
      ul{
        display:flex;
        flex-direction: column;
        gap:3px;
      }
    }

    .top_logo{
      order:2;
    }
    @media screen and (max-width: 768px) {
      .news_list{
        order:2;
      }

      .top_logo{
        order:1;
      }
    }

  }

  @media screen and (max-width: 1180px) {
    gap:45px;
    .hero_left{
      width:32%;
    }
    .hero_right{
      width:32%;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top:145px;
    flex-direction: column;
    gap:15px;
    .hero_left{
      width:86%;
      height:auto;
      img{
        width:100%;
      }
    }

    #mvtk-widgets-container{
      position:initial;
      //width:94%;
      margin:0 auto;
    }
    .hero_right{
      width:94%;
      height:auto;
    }

    .hero_center{
      max-width:52px;
    }
  }
}

