/* NEWS
----------------------------------------------------- */

.news_list{
  cursor:pointer;
  border-radius:4px;
  border:thin solid var(--gold);
  background-color: #0e0d0dce;
  padding:7px;
  margin:20px auto 0 auto;
  width:100%;
  max-width:720px;
  font-size:0.8rem;
  color:var(--gold0);
  font-weight:bold;


  span{
    background-color: var(--gold);
    padding:0 3px;
    border-radius: 2px;
    color:var(--black);
    text-align: center;
    margin:5px 5px 8px 0;
    min-width:80px;
    display:inline-block;
  }

  a {
    color:var(--gold0);
  }
    ul{
      margin: 0 auto;
      li{
        line-height:1.8;
        &:before {
        content:'†';
        }
      }
    }
}

#news {
  background-image: url("https://inorinotani.com/images/mountain_bg.png");
  //background-attachment: fixed;
  background-size: cover;
  background-position: center 100%;
  background-color: #130d4d;
  padding-bottom:380px !important;
  color:var(--white);
  a {
    color:var(--gold_light);
  }

  .subBlock {
    border-radius:6px;
    border:thin solid var(--gold);
    padding:34px 10px 10px 10px;
    text-align: center;
    margin: 40px auto 40px auto;
    position:relative;

    span.news_no{
      //background-color: var(--gold);
      padding:1px 5px;
      border-bottom:thin solid var(--gold);
      position:absolute;
      left:0;
      top:0;
      color:var(--gold);
    }

    .news_images{
      display:flex;
      width:98%;

      margin:10px auto;
      gap:10px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      img{
        flex:1;
        max-width:300px;
        @media screen and (max-width: 668px) {
          max-width:100%;
        }
      }


    }


    p {
      text-align: left;

      &.center{
        text-align: center;
      }

      span{
        min-width:110px;
        margin-right:10px;
        padding:0 3px;
        color:var(--white);
        display:inline-block;
        background-color: var(--gold);
        font-size:0.86em;
      }

      blockquote{
        text-indent:120px;

        &.small{
          font-size:0.8em;
        }
      }
    }
  }

  h3 {
    padding: 6px 12px;
    display: inline-block;
    margin-bottom:1.5rem;
    color:var(--gold_light);
    border-bottom:thin solid var(--gold_light);
  }
}


@media screen and (max-width: 768px) {
  #news {
    background-attachment: fixed;

    .subBlock {
      text-align: center;
      margin: 20px auto 20px auto;

      h3{
        padding:4px;
      }

      p{
        font-size:0.8rem;
        span{
          display:block;
          text-align:center;
          margin:2px 0;
          padding:3px;
        }

        blockquote{
          text-indent:0;
        }
      }
    }
  }
}

