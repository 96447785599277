.menu{
  background-color: var(--black);
  z-index:100;
  position:fixed;
  top:0;
  right:0;
  .gnavi{
    display: flex;
    flex-wrap: wrap;
    margin:0;
    list-style: none;
    justify-content: center;

    li{
      &.logo_img{
        flex:1;
      }
      font-size:1.0rem;
      margin:0;
      cursor:pointer;
      padding:5px 8px;
      a{
        display: block;
        padding:3px 6px;
        text-decoration: none;
        color: #fff;
      }

      .home{
        position:fixed;
        left:0;
        top:0;
        height:40px;
        background-color: var(--black);
        img{
          height:100%;
          width:auto;
        }
      }

      @media screen and (max-width: 1020px) {
        font-size:0.9rem;
        margin:0;
        cursor:pointer;
        padding:2px;

        a{
          padding:2px 3px;
        }
      }

      @media screen and (max-width: 850px) {
        align-items: center;
        padding:3px 3px;
        font-size:0.8rem;

        .home{
          position:static;
          display:block;
          width:100vw;
          height:45px;
          text-align: center;
        }
      }

      @media screen and (max-width: 520px) {
        .home{
          img{
            width:100%;
            height:auto;
          }
        }
      }
    }
  }

  .gnavi li {
    a{
      position: relative;
      overflow: hidden;
      transition: all .2s;
    }

    /*現在地とhoverの設定*/
    &.current a, a:hover{
      background: var(--gold);
      color:#fff;
    }

    /*背景がつくのアニメーションの開始を0.5秒遅らせる*/
    a:hover{
      transition-delay: .5s;
    }

    a:before,
    a:after {
      content: "";
      position: absolute;
      left: 0;
      /*線の形状*/
      height: 2px;
      width:100%;
      background:var(--gold);
      transition: all .5s;
    }

    a::before {
      top: 0;
      transform: translateX(-100%);
    }
    a::after {
      bottom: 0;
      transform: translateX(100%);
    }
    a:hover::before,
    a:hover::after {
      transform: translateX(0);/*X方向に0%移動*/
    }
  }
}
