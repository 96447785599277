.section_preloader{
  @include flex();
  justify-content: center;
  align-items: center;
  user-select:none;
  pointer-events: none;
  min-height:300px;
  min-width:100%;
  width:100%;
  @media screen and (max-width: 768px) {
    min-height:90px;
  }

  //opacity: 1;
  &.show{
    @include animation(fadeOut 2.0s ease-in 0.3s forwards);
   opacity: 1;
  }

  &.longer{
    @include animation(fadeOut 12.0s ease-in 0s forwards);
    opacity: 1;
  }

  &.hide{
    @include animation(fadeOut 4.5s ease-in 0.5s forwards);
    opacity:0;
  }
}


#preloader{
  display:flex;
  justify-content: center;
  align-items: center;
  user-select:none;
  pointer-events: none;
  position:fixed;
  top:0;
  left:0;
  margin:0;
  width:100vw !important;
  min-width:100vw;
  height:100vh;
  z-index:200;
  background-color: #170d24;
  background-image:
          radial-gradient(ellipse at 10% 90%, #3a2511 0%, transparent 55%),
          radial-gradient(ellipse at 90% 90%, #313131 0%, transparent 55%),
          radial-gradient(ellipse at 90% 10%, #181715 0%, transparent 55%),
          radial-gradient(ellipse at 10% 10%, #171411 0%, transparent 55%);
  &.show{
    @include animation(fadeOut 0.2s ease-in 0s forwards);
  }

  &.hide{
   opacity:1;
  }

  .logos{
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    width:100%;
    z-index:10;

    & > * {
      width:100%;
    }
  }

  .img_area{
    //position:absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    width:60%;
    z-index:10;
    max-with:350px;
    margin:0 auto;
  }

}
