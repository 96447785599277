/* footer
----------------------------------------------------- */
@media screen and (min-width: 769px) {
  footer {
    background: var(--black);
    text-align: center;
    padding: 20px 0;

    img {
      max-width: 788px;
      width: 100%;
    }

    p {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    background: var(--black);
    text-align: center;
    padding: 30px 0;
    img {
      width: 90%;
    }

    p {
      display: none;
    }
  }
}
