@include keyframes(fadeIn){
  from {opacity: 0;}
  to {opacity: 1;}
}

@include keyframes(fadeOut){
  from {opacity: 1;}
  to {opacity: 0;}
}

@include keyframes(slideShineAnime) {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 0.8;
    transform: translateX(100%);
  }
}
