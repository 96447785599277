$color_1: #000;
$color_2: #d1b872;
$background-color_1: #1d1d1d;
$background-color_2: #c99c16;

#theater {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0 0 10vw;
  // background-color: $background-color_1;

  background: linear-gradient(-40deg,#573603  0%,  #0a0a0a 100%);
  text-align: center;
  img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
  h2 {
    width: 100%;
    height: auto;
    margin: 0 0 3vw;
    padding: 0;
  }
  #theater-inner {
    display: inline-block;
    width: 100%;
    height: auto;
    max-width: 1000px;

    #area-index {
      width: 90%;
      height: auto;
      margin: 10vw 5% 0vw;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        width: auto;
        padding: 0 20px;
        border-right: 1px solid #fff;
        &:last-child {
          border-right: none;
        }
        a {
          font-size: 1.2em;
          letter-spacing: 2px;
          font-feature-settings: "palt";
          color: $color_2;
          &:hover {
            animation: flash 1.5s;
          }
        }
      }
    }
    h4 {
      width: 100%;
      height: auto;
      margin: 5vw 0 2vw;
      padding: 5vw 0 0;
      text-align: center;
      font-size: 1.3em;
      color: $color_2;
      letter-spacing:1px;
    }
    h4#area1 {
      margin: 0vw 0 2vw;
      padding: 2vw 0 0;
      @media (max-width: 1024px) {
        span{
          display:block;
        }
      }
    }
    .theater-list {
      width: 80%;
      height: auto;
      margin: 0 10%;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        height: auto;
        margin: 0 0 1vw;
        padding: 0.5vw 0 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .icontop {
          width: 30px;
          display: none;
        }
        .txt {
          width: auto;
          height: auto;
          margin-left: 0.5em;
          font-size: 1.0em;
          line-height: 1.0em;
          font-feature-settings: "palt";
          letter-spacing: 2px;
          color: $color_2;
          text-align: left;
          a {
            color: $color_2;
            text-decoration: underline;
          }
        }
      }
      .todofuken {
        width: 15%;
      }
      .name {
        width: 43%;
      }
      .date {
        width: 20%;
      }
      .seat {
        width: 22%;
      }
      .line {
        width: 100%;
        padding: 0 30% 0.5vw 20%;
        margin-bottom: 1vw;
        border-bottom: 1px solid #6b5208;
        .txt {
          font-size: 0.9em;
          line-height: 1.6em;
          font-feature-settings: "palt";
          letter-spacing: 1px;
        }
      }
      .top {
        display: flex;
        border-bottom: 1px solid #c99c16;
        .icontop {
          width: 15px;
          display: block;
          transform: translateY(-3px);
          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #theater {
    padding: 0 0 20vw;
    #theater-inner {
      #area-index {
        width: 100%;
        height: auto;
        margin: 20vw 0 10vw;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
          margin: 5px 0;
          padding: 0 15px;
        }
      }
      h4 {
        width: 100%;
        height: auto;
        margin: 5vw 0 2vw;
        padding: 0;
        text-align: center;
        font-size: 1.4em;
        color: $color_2;
      }
      .theater-list {
        width: 90%;
        height: auto;
        margin: 0 5%;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
          height: auto;
          margin: 0 0 2vw;
          padding: 0 0 1vw;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          .icontop {
            width: 5%;
            display: block;
            img {
              vertical-align: middle;
              transform: translateY(-3px);
            }
          }
        }
        .todofuken {
          width: 100%;
          border-top: 1px solid #c99c16;
          border-bottom: none;
          padding-top: 5vw;
        }
        .name {
          width: 100%;
          border-bottom: none;
        }
        .date {
          width: 100%;
          border-bottom: none;
        }
        .seat {
          width: 100%;
          border-bottom: none;
        }
        .line {
          width: 100%;
          padding: 0;
          margin-bottom: 1vw;
          border-bottom: none;
        }
        .top {
          display: none;
        }
      }
    }
  }
}
