
#staff{
  ul{
    background-color: rgba(14, 14, 14, 0.71);
    padding:1rem;
    border-radius:6px;
  }
}

/*  CAST STAFF
----------------------------------------------------- */
@media screen and (min-width: 769px) {
  #cast {
    background: #f6f6f8 url(https://inorinotani.com/images/staff_bg_reira2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:100% center;
  }

  #staff {
    background: #f6f6f8 url(https://inorinotani.com/images/staff_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:100% center;
  }

  #cast, #staff {
    ul {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-around;
      -ms-flex-pack: justify;
      justify-content: space-around;
    }

    li {
      width: 100%;
      text-align: center;
      margin:1rem 0;
      display:flex;
      gap:20px;
      color:var(--white);

      .thumb_area{
        margin-bottom:12px;
      }
    }
  }

  #cast {
    ul {
      li{
        &:nth-child(even){
          .thumb_area{
            order:1;
          }
          .staffImg{
            order:2;
          }
        }
      }
    }
  }

  #staff{
    ul{
      li{
        flex-direction: column;
      }
    }
  }


  img.staffImg {
    width: 250px;
    border:2px solid var(--gold);
    box-shadow: rgba(124, 91, 20, 0.3) 0px 0px 16px;
  }



  #staff, #cast {
    padding: 60px 0 100px 0;

    li h6 {
      font-size: 80%;
    }
  }

}

@media screen and (max-width: 768px) {
  #cast {
    background: #f6f6f8 url(https://inorinotani.com/images/staff_bg_reira2_sp.jpg);
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
  }

  #staff {
    background: #f6f6f8 url(https://inorinotani.com/images/staff_bg.jpg);
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
  }

  #cast, #staff {
    ul {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-around;
      -ms-flex-pack: justify;
      justify-content: space-around;
    }

    li {
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
      flex-direction: column;
      color:var(--white);

      .thumb_area{
        margin-bottom:12px;
      }

    }
  }

  img.staffImg{
    max-width: 300px;
    width:90%;
    border:2px solid var(--gold);
    box-shadow: rgba(124, 91, 20, 0.3) 0px 0px 16px;
  }



  #staff, #cast {
    padding: 10% 0 20% 0;
    li h6 {
      font-size: 80%;
    }
  }

}

.modalButton {
  display: block;
  width: 100px;
  margin: 10px auto;
  background: var(--gold);
  color: #f0f0f2 !important;
  font-size: 70%;
  padding: 1px 0 3px 0;
  letter-spacing: 1px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .modalButton {
    display: block;
    width: 90px;
    margin: 10px auto;
    background: var(--gold);
    color: #f0f0f2 !important;
    font-size: 9px;
    padding: 1px 0 2px 0;
    letter-spacing: 1px;
    text-align: center;
  }
}

/* MODAL
----------------------------------------------------- */
@media screen and (min-width: 769px) {
  .modal {
    padding: 30px;
  }

  .staffmodal h4 {
    margin-top: 40px;
  }

  .staffmodal h5, .staffmodal h5 {
    font-size: 140%;
    line-height: 1.4;
  }

  .staffmodal p {
    margin-top: 40px;
    clear: both;
  }

  .staffmodal {
    p {
      margin-top: 40px;
      clear: both;
    }

    h4, h5, h6 {
      text-align: left;
    }

    .staffImg {
      float: right;
      width: 153px;
      margin: 5px 0 25px 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    padding: 10% 5%;
  }

  .staffmodal h4 {
    margin-top: 40px;
  }

  .staffmodal h5, .staffmodal h5 {
    font-size: 140%;
    line-height: 1.4;
  }

  .staffmodal p {
    margin-top: 40px;
    clear: both;
  }

  .staffmodal {
    p {
      margin-top: 40px;
      clear: both;
    }

    h4, h5, h6 {
      text-align: left;
    }

    .staffImg {
      float: right;
      width: 153px;
      margin: 5px 0 25px 20px;
    }
  }
}
