:root {
  --zero: #030448;
  --black: #0e0d0d;
  --gray: #4b4949;
  --gold0: #d59a0d;
  --gold_light: #c78d09;
  --gold: #936b09;
  --gold2: #674703;
  --white:#fffffe;
  --primary: #033f21;
  --primary2: rgba(0, 0, 0, 0.24);
  --primary-trans80: rgba(214, 26, 32, 0.98);
  --secondary: #1e1b1b;
  --secondary2: #ee9b03;
  --secondary_trans:#ffce10de;
  ---font-family-hiragino:'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif;
  --font-family-main: "Open Sans", "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", sans-serif;
}


