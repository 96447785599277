.fade-in{
  opacity:0;
  @include animation(fadeIn 1s ease-in forwards);
}

.fade-in-1s{
  opacity:0;
  @include animation(fadeIn 1s ease-in 0s forwards);
}

.fade-in-2s{
  opacity:0;
  @include animation(fadeIn 0.5s ease-in 0.5s forwards);
}

.fade-in-1s-1s{
  opacity:0;
  @include animation(fadeIn 1s ease-in 1s forwards);
}

.fade-in-2s-2s{
  opacity:0;
  @include animation(fadeIn 2s ease-in 2s forwards);
}


.fade-in-3s-3s{
  opacity:0;
  @include animation(fadeIn 3s ease-in 3s forwards);
}


.fade-out{
  opacity:0;
  @include animation(fadeOut 1s ease-in forwards);
}

.fade-out-1s{
  opacity:0;
  @include animation(fadeOut 1s ease-in 0s forwards);
}

.fade-out-2s{
  opacity:0;
  @include animation(fadeOut 0.5s ease-in 0.5s forwards);
}

.fade-out-1s-1s{
  opacity:0;
  @include animation(fadeOut 1s ease-in 1s forwards);
}

.fade-out-2s-2s{
  opacity:0;
  @include animation(fadeOut 2s ease-in 2s forwards);
}


/****** ****/


.anime_top_down{
  animation: TopDown 1.6s forwards;
}

/* CSSアニメーションの設定 */
@keyframes TopDown {
  0% {
    opacity: 0;/*初期状態では透明に*/
    transform: translateY(-8px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.anime_bottom_up{
  animation: BottomUp 1.6s forwards;
}

/* CSSアニメーションの設定 */
@keyframes BottomUp {
  0% {
    opacity: 0;/*初期状態では透明に*/
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
