/* KEYWORD
----------------------------------------------------- */
#keyword {
  .gergia_info{

    .gergia_info_inner{
      background: linear-gradient(0.25turn, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0));
      padding:10px;
      border-radius:5px;
    }

     p{
       margin-bottom:8px;
       span{
         margin-right:10px;
         background-color: var(--gold);
         color:var(--white);
         font-size:75%;
         padding:3px 5px;
         letter-spacing: 2px;
       }
     }
  }
}


@media screen and (min-width: 769px) {
  #keyword {
    padding-bottom:380px !important;
    .subBlock {
      text-align: center;
      margin: 40px auto 40px auto;

      p {
        text-align: left;
        &.center{
          text-align: center;
        }
      }
    }

    h2 {
      padding: 6px 50px;
      min-width:420px;
      display: inline-block;
      color: #f0f0f2;
      text-align: center;
      margin-bottom:1rem;
    }
  }

  #keyword {
   background-image: url("https://inorinotani.com/images/about_back_hill.jpg");
    //background-attachment: fixed;
    background-size: cover;
    background-position: center 100%;
    background-color: #fff;


    #gergia02 {
      text-align: left;
      font-family: "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
      position: relative;

      h6 {
        text-align: left;
        margin-bottom: 8px;
      }

      table {
        font-size: 90%;

        th {
          font-weight: 700;
          word-break: keep-all;
          position: relative;
          padding-right: 13px;

          &::after {
            content: ':';
            position: absolute;
            right: 3px;
            top: 0;
          }
        }
      }
    }

    #toribisi {
      p {
        margin: 45px 0 0 0;
      }

      margin-bottom: 0;
    }

    h2 {
      //background: var(--gold);
    }
  }

  .gergiaImg01 {
    max-width: 420px;
    float: left;
    margin: 0px 20px 20px -10%;
    width: 60%;
  }

  .gergiaImg02 {
    width: 88px;
    position: absolute;
    right: 0;
    top: 0px;
  }

  .tobirisiImg01 {
    width: 467px;
    float: right;
    margin: 5px -5% 0px 20px;
  }

  .tobirisiImg02 {
    width: 298px;
    margin: 30px auto 0 auto;
  }
}

@media screen and (max-width: 768px) {
  #keyword {
    padding-bottom:420px !important;
    .subBlock {
      text-align: center;
      margin: 40px auto 40px auto;

      p {
        text-align: left;
      }
    }

    h2 {
      padding: 6px 50px;
      width:100%;
      display: inline-block;
      color: #f0f0f2;
      text-align: center;
      margin-bottom:1rem;
    }
  }

  #keyword {
    background-image: url("https://inorinotani.com/images/about_back_hill.jpg");
    //background-attachment: fixed;
    background-size: cover;
    background-position: center;

    #gergia {
      color: var(--gold);
    }

    #gergia02 {
      text-align: left;
      font-family: "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
      position: relative;

      h6 {
        text-align: left;
        margin-bottom: 8px;
      }

      table {
        font-size: 90%;

        th {
          font-weight: 700;
          word-break: keep-all;
          position: relative;
          padding-right: 13px;

          &::after {
            content: ':';
            position: absolute;
            right: 3px;
            top: 0;
          }
        }
      }
    }

    h2 {
      //background: var(--gold);
    }

  }

  .kintugiImg01 {
    width: 100%;
    margin: 0 auto 20px auto;
  }

  .gergiaImg01 {
    width: 100%;
    margin: 0 auto 20px auto;
  }

  .gergiaImg02 {
    width: 58px;
    position: absolute;
    right: 0;
    top: 0px;
  }

  .tobirisiImg01 {
    width: 100%;
    margin: 0 auto 20px auto;
  }

  .tobirisiImg02 {
    width: 60%;
    margin: 10px 0 30px 40%;
  }
}

