#theater-inner {
  #udcast {
    width: 80%;
    height: auto;
    margin: 3vw 10% 3vw;
    padding: 1vw 0 2vw;
    box-sizing: border-box;
    border: 3px solid #fff;
    background-color: $background-color_2;

    #ud-banners {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 2vw 0;
      list-style-type: none;
      display: flex;
      justify-content: center;

      li {
        width: 15%;
        height: auto;
        margin: 0 1%;
      }
    }

    h3 {
      width: 100%;
      height: auto;
      margin: 0 0 2vw;
      padding: 0;
      text-align: center;
      font-size: 1.4em;
      color: $color_1;
    }

    p {
      width: 90%;
      height: auto;
      margin: 0 5% 2vw;
      padding: 0;
      text-align: left;
      font-size: 1em;
      color: $color_1;
      word-break: break-all;
    }

    .line {
      width: 90%;
      height: auto;
      margin: 10px 5%;
      border-top: 4px dotted #000;
    }

    h4 {
      width: 100%;
      height: auto;
      margin: 2vw 0 0.5vw;
      padding: 0;
      text-align: center;
      font-size: 1.2em;
      color: $color_1;
    }
  }
}


@media (max-width: 1024px) {
  #theater {
    padding: 0 0 20vw;

    #theater-inner {
      #udcast {
        width: 90%;
        height: auto;
        margin: 10vw 5% 6vw;
        padding: 5vw 0 3vw;
        box-sizing: border-box;
        border: 3px solid #fff;
        background-color: $background-color_2;

        #ud-banners {
          li {
            width: 25%;
            height: auto;
            margin: 0 1%;
          }
        }

        h3 {
          width: 100%;
          height: auto;
          margin: 2vw 0 5vw;
          padding: 0;
          text-align: center;
          font-size: 1.4em;
          color: $color_1;
        }

        .line {
          width: 90%;
          height: auto;
          margin: 20px 5%;
          border-top: 4px dotted #000;
        }
      }
    }
  }
}
