#twitter_module{
  width:300px;
  position:absolute;
  top:50px;
  right:20px;
  z-index:10;
  @media screen and (max-width: 768px) {

    position:static;
    width:calc(100% - 40px);
    margin:20px;


  }
}
