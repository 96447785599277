#mvtk-widgets-container {
  //position:absolute;
  //left:20px;
  //bottom:20px;
  //max-width: 280px;
  margin: 10px auto 0px auto;
  text-align:center;

  @media screen and (max-width: 768px) {
    //position: initial;
    ////width:94%;
    margin: 10px auto 0px auto;
  }
}
