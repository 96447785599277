@charset "UTF-8";
*, :after, :before {
  box-sizing: border-box !important;
}
img {
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
}

.sp_u {
  display: none;
}

.pc_u {
  display: initial;
}

@media screen and (max-width: 580.98px) {
  .sp_u {
    display: initial;
  }

  .pc_u {
    display: none;
  }
}

html {
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
  font-family: var(---font-family-hiragino);
}

body {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

