/* 	#photoArea01
----------------------------------------------------- */
@media screen and (min-width: 769px) {
  #photoArea001 {
    width: 100%;
    height: 657px;
    background: url(https://inorinotani.com/images/kin/photo1_horizontal.jpg) center top no-repeat;
    background-size: auto 100%;
  }
}

@media screen and (max-width: 768px) {
  #photoArea001 {
    position: relative;
    width: 100%;
    height: 70vw;
    background: url(https://inorinotani.com/images/kin/photo1_horizontal.jpg) center top no-repeat;
    background-size: auto 100%;
  }
}


/* PhotoArea02
----------------------------------------------------- */
@media screen and (min-width: 769px) {
  #photoArea02 {
    width: 100%;
    height: 657px;
    background: url(https://inorinotani.com/images/kin/keyword_bg.jpg) center top no-repeat;
    background-size: auto 100%;
  }
}

@media screen and (max-width: 768px) {
  #photoArea02 {
    width: 100%;
    height: 70vw;
    background: url(https://inorinotani.com/images/kin/keyword_bg.jpg) top center no-repeat;
    background-size: 250% auto;
  }
}
