#svg_logo_div {

  .st0{
    fill: #1a1a18;
    stroke: #5d512d;
    stroke-miterlimit:10;
  }
  .st1{
    fill:#000;
  }
  .st2{
    fill:#000;
  }

  @-webkit-keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 475.65277099609375px;
      stroke-dasharray: 475.65277099609375px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 475.65277099609375px;
    }
  }

  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 475.65277099609375px;
      stroke-dasharray: 475.65277099609375px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 475.65277099609375px;
    }
  }

  @-webkit-keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-1 {
    -webkit-animation: animate-svg-stroke-1 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
    animate-svg-fill-1 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
    animation: animate-svg-stroke-1 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
    animate-svg-fill-1 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
  }

  @-webkit-keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 544.0768432617188px;
      stroke-dasharray: 544.0768432617188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 544.0768432617188px;
    }
  }

  @keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 544.0768432617188px;
      stroke-dasharray: 544.0768432617188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 544.0768432617188px;
    }
  }

  @-webkit-keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-2 {
    -webkit-animation: animate-svg-stroke-2 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
    animate-svg-fill-2 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
    animation: animate-svg-stroke-2 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
    animate-svg-fill-2 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  }

  @-webkit-keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 55.517799377441406px;
      stroke-dasharray: 55.517799377441406px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 55.517799377441406px;
    }
  }

  @keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 55.517799377441406px;
      stroke-dasharray: 55.517799377441406px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 55.517799377441406px;
    }
  }

  @-webkit-keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-3 {
    -webkit-animation: animate-svg-stroke-3 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
    animate-svg-fill-3 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
    animation: animate-svg-stroke-3 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
    animate-svg-fill-3 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }

  @-webkit-keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 56.65348815917969px;
      stroke-dasharray: 56.65348815917969px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.65348815917969px;
    }
  }

  @keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 56.65348815917969px;
      stroke-dasharray: 56.65348815917969px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.65348815917969px;
    }
  }

  @-webkit-keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-4 {
    -webkit-animation: animate-svg-stroke-4 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
    animate-svg-fill-4 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
    animation: animate-svg-stroke-4 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
    animate-svg-fill-4 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
  }

  @-webkit-keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 146.75897216796875px;
      stroke-dasharray: 146.75897216796875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 146.75897216796875px;
    }
  }

  @keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 146.75897216796875px;
      stroke-dasharray: 146.75897216796875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 146.75897216796875px;
    }
  }

  @-webkit-keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-5 {
    -webkit-animation: animate-svg-stroke-5 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
    animate-svg-fill-5 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
    animation: animate-svg-stroke-5 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
    animate-svg-fill-5 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  }

  @-webkit-keyframes animate-svg-stroke-6 {
    0% {
      stroke-dashoffset: 109.95252990722656px;
      stroke-dasharray: 109.95252990722656px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 109.95252990722656px;
    }
  }

  @keyframes animate-svg-stroke-6 {
    0% {
      stroke-dashoffset: 109.95252990722656px;
      stroke-dasharray: 109.95252990722656px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 109.95252990722656px;
    }
  }

  @-webkit-keyframes animate-svg-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-6 {
    -webkit-animation: animate-svg-stroke-6 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
    animate-svg-fill-6 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
    animation: animate-svg-stroke-6 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
    animate-svg-fill-6 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }

  @-webkit-keyframes animate-svg-stroke-7 {
    0% {
      stroke-dashoffset: 107.93161010742188px;
      stroke-dasharray: 107.93161010742188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 107.93161010742188px;
    }
  }

  @keyframes animate-svg-stroke-7 {
    0% {
      stroke-dashoffset: 107.93161010742188px;
      stroke-dasharray: 107.93161010742188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 107.93161010742188px;
    }
  }

  @-webkit-keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-7 {
    -webkit-animation: animate-svg-stroke-7 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
    animate-svg-fill-7 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s both;
    animation: animate-svg-stroke-7 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
    animate-svg-fill-7 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s both;
  }

  @-webkit-keyframes animate-svg-stroke-8 {
    0% {
      stroke-dashoffset: 475.4440002441406px;
      stroke-dasharray: 475.4440002441406px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 475.4440002441406px;
    }
  }

  @keyframes animate-svg-stroke-8 {
    0% {
      stroke-dashoffset: 475.4440002441406px;
      stroke-dasharray: 475.4440002441406px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 475.4440002441406px;
    }
  }

  @-webkit-keyframes animate-svg-fill-8 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-8 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-8 {
    -webkit-animation: animate-svg-stroke-8 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
    animate-svg-fill-8 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
    animation: animate-svg-stroke-8 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
    animate-svg-fill-8 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
  }

  @-webkit-keyframes animate-svg-stroke-9 {
    0% {
      stroke-dashoffset: 149.3382110595703px;
      stroke-dasharray: 149.3382110595703px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 149.3382110595703px;
    }
  }

  @keyframes animate-svg-stroke-9 {
    0% {
      stroke-dashoffset: 149.3382110595703px;
      stroke-dasharray: 149.3382110595703px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 149.3382110595703px;
    }
  }

  @-webkit-keyframes animate-svg-fill-9 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-9 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-9 {
    -webkit-animation: animate-svg-stroke-9 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
    animate-svg-fill-9 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-stroke-9 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
    animate-svg-fill-9 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }

  @-webkit-keyframes animate-svg-stroke-10 {
    0% {
      stroke-dashoffset: 227.3223114013672px;
      stroke-dasharray: 227.3223114013672px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 227.3223114013672px;
    }
  }

  @keyframes animate-svg-stroke-10 {
    0% {
      stroke-dashoffset: 227.3223114013672px;
      stroke-dasharray: 227.3223114013672px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 227.3223114013672px;
    }
  }

  @-webkit-keyframes animate-svg-fill-10 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-10 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-10 {
    -webkit-animation: animate-svg-stroke-10 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
    animate-svg-fill-10 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-stroke-10 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
    animate-svg-fill-10 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  }

  @-webkit-keyframes animate-svg-stroke-11 {
    0% {
      stroke-dashoffset: 138.33152770996094px;
      stroke-dasharray: 138.33152770996094px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 138.33152770996094px;
    }
  }

  @keyframes animate-svg-stroke-11 {
    0% {
      stroke-dashoffset: 138.33152770996094px;
      stroke-dasharray: 138.33152770996094px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 138.33152770996094px;
    }
  }

  @-webkit-keyframes animate-svg-fill-11 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-11 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-11 {
    -webkit-animation: animate-svg-stroke-11 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
    animate-svg-fill-11 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-stroke-11 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
    animate-svg-fill-11 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @-webkit-keyframes animate-svg-stroke-12 {
    0% {
      stroke-dashoffset: 176.61422729492188px;
      stroke-dasharray: 176.61422729492188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 176.61422729492188px;
    }
  }

  @keyframes animate-svg-stroke-12 {
    0% {
      stroke-dashoffset: 176.61422729492188px;
      stroke-dasharray: 176.61422729492188px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 176.61422729492188px;
    }
  }

  @-webkit-keyframes animate-svg-fill-12 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-12 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-12 {
    -webkit-animation: animate-svg-stroke-12 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
    animate-svg-fill-12 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-stroke-12 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
    animate-svg-fill-12 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
  }

  @-webkit-keyframes animate-svg-stroke-13 {
    0% {
      stroke-dashoffset: 173.81033325195312px;
      stroke-dasharray: 173.81033325195312px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 173.81033325195312px;
    }
  }

  @keyframes animate-svg-stroke-13 {
    0% {
      stroke-dashoffset: 173.81033325195312px;
      stroke-dasharray: 173.81033325195312px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 173.81033325195312px;
    }
  }

  @-webkit-keyframes animate-svg-fill-13 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-13 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-13 {
    -webkit-animation: animate-svg-stroke-13 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
    animate-svg-fill-13 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
    animation: animate-svg-stroke-13 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
    animate-svg-fill-13 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
  }

  @-webkit-keyframes animate-svg-stroke-14 {
    0% {
      stroke-dashoffset: 96.36974334716797px;
      stroke-dasharray: 96.36974334716797px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 96.36974334716797px;
    }
  }

  @keyframes animate-svg-stroke-14 {
    0% {
      stroke-dashoffset: 96.36974334716797px;
      stroke-dasharray: 96.36974334716797px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 96.36974334716797px;
    }
  }

  @-webkit-keyframes animate-svg-fill-14 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-14 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-14 {
    -webkit-animation: animate-svg-stroke-14 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
    animate-svg-fill-14 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
    animation: animate-svg-stroke-14 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
    animate-svg-fill-14 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
  }

  @-webkit-keyframes animate-svg-stroke-15 {
    0% {
      stroke-dashoffset: 213.9276123046875px;
      stroke-dasharray: 213.9276123046875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 213.9276123046875px;
    }
  }

  @keyframes animate-svg-stroke-15 {
    0% {
      stroke-dashoffset: 213.9276123046875px;
      stroke-dasharray: 213.9276123046875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 213.9276123046875px;
    }
  }

  @-webkit-keyframes animate-svg-fill-15 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-15 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-15 {
    -webkit-animation: animate-svg-stroke-15 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
    animate-svg-fill-15 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
    animation: animate-svg-stroke-15 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
    animate-svg-fill-15 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
  }

  @-webkit-keyframes animate-svg-stroke-16 {
    0% {
      stroke-dashoffset: 38.83766174316406px;
      stroke-dasharray: 38.83766174316406px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 38.83766174316406px;
    }
  }

  @keyframes animate-svg-stroke-16 {
    0% {
      stroke-dashoffset: 38.83766174316406px;
      stroke-dasharray: 38.83766174316406px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 38.83766174316406px;
    }
  }

  @-webkit-keyframes animate-svg-fill-16 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-16 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-16 {
    -webkit-animation: animate-svg-stroke-16 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
    animate-svg-fill-16 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
    animation: animate-svg-stroke-16 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
    animate-svg-fill-16 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
  }

  @-webkit-keyframes animate-svg-stroke-17 {
    0% {
      stroke-dashoffset: 34.95787048339844px;
      stroke-dasharray: 34.95787048339844px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 34.95787048339844px;
    }
  }

  @keyframes animate-svg-stroke-17 {
    0% {
      stroke-dashoffset: 34.95787048339844px;
      stroke-dasharray: 34.95787048339844px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 34.95787048339844px;
    }
  }

  @-webkit-keyframes animate-svg-fill-17 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-17 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-17 {
    -webkit-animation: animate-svg-stroke-17 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
    animate-svg-fill-17 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
    animation: animate-svg-stroke-17 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
    animate-svg-fill-17 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
  }

  @-webkit-keyframes animate-svg-stroke-18 {
    0% {
      stroke-dashoffset: 132.50296020507812px;
      stroke-dasharray: 132.50296020507812px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 132.50296020507812px;
    }
  }

  @keyframes animate-svg-stroke-18 {
    0% {
      stroke-dashoffset: 132.50296020507812px;
      stroke-dasharray: 132.50296020507812px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 132.50296020507812px;
    }
  }

  @-webkit-keyframes animate-svg-fill-18 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  @keyframes animate-svg-fill-18 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(185, 154, 0);
    }
  }

  .svg-elem-18 {
    -webkit-animation: animate-svg-stroke-18 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
    animate-svg-fill-18 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
    animation: animate-svg-stroke-18 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
    animate-svg-fill-18 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
  }

  @-webkit-keyframes animate-svg-stroke-19 {
    0% {
      stroke-dashoffset: 2107.588134765625px;
      stroke-dasharray: 2107.588134765625px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 2107.588134765625px;
    }
  }

  @keyframes animate-svg-stroke-19 {
    0% {
      stroke-dashoffset: 2107.588134765625px;
      stroke-dasharray: 2107.588134765625px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 2107.588134765625px;
    }
  }

  @-webkit-keyframes animate-svg-fill-19 {
    0% {
      fill: transparent;
    }

    100% {
      fill: #fff;
    }
  }

  @keyframes animate-svg-fill-19 {
    0% {
      fill: transparent;
    }

    100% {
      fill: #fff;
    }
  }

  .svg-elem-19 {
    -webkit-animation: animate-svg-stroke-19 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
    animate-svg-fill-19 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
    animation: animate-svg-stroke-19 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
    animate-svg-fill-19 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
  }

  @-webkit-keyframes animate-svg-stroke-20 {
    0% {
      stroke-dashoffset: 530.5470581054688px;
      stroke-dasharray: 530.5470581054688px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 530.5470581054688px;
    }
  }

  @keyframes animate-svg-stroke-20 {
    0% {
      stroke-dashoffset: 530.5470581054688px;
      stroke-dasharray: 530.5470581054688px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 530.5470581054688px;
    }
  }

  @-webkit-keyframes animate-svg-fill-20 {
    0% {
      fill: transparent;
    }

    100% {
      fill: #fff;
    }
  }

  @keyframes animate-svg-fill-20 {
    0% {
      fill: transparent;
    }

    100% {
      fill: #fff;
    }
  }

  .svg-elem-20 {
    -webkit-animation: animate-svg-stroke-20 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
    animate-svg-fill-20 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
    animation: animate-svg-stroke-20 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
    animate-svg-fill-20 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
  }
}


