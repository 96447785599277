

p.center{
  text-align:center;
}

/* RUBY
----------------------------------------------------- */

.ruby {
  position: relative;
  overflow: visible;
}

.rb {
  position: absolute;
  font-size: 6px;
  top: -11px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0px;
  transform: scale(0.8);
}

@media screen and (max-width: 768px) {
  .rb {
    top: -6px;
  }
}

/* BASE
----------------------------------------------------- */

#wrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  z-index: 4;

  section {
    width: 100%;
  }
}

.subBlock {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 769px) {
  #wrapper {
    section {
      padding: 60px 0 100px 0;
    }
  }

  .subBlock {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  #wrapper section {
    padding: 92px 0 20% 0;
  }

  .subBlock {
    width: 88%;
  }
}

/* SUBTITLE
----------------------------------------------------- */

.subtitle{
  display:flex;
  justify-content: center;
  position:relative;
}

.subtitle img {
  width: 100%;
}

.subtitle span{
  position:absolute;
  opacity:0;
  user-select:none;
}

.comming_soon{
  display:flex;
  justify-content: center;
  align-items: center;

  span{
    background-color: var(--gold);
    padding:5px 12px;
    margin:12px;
    letter-spacing: 1px;
  }
}

@media screen and (min-width: 769px) {
  .subtitle {
    width: 80px;
    margin: 0px auto 40px auto;
  }
}

@media screen and (max-width: 768px) {
  .subtitle {
   width: 65px;
    margin: 0px auto 40px auto;
  }
}

/* LOADING
---------------------------------------------------- */

#loading {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 130px;
  display: block;

  img {
    width: 100%;
  }
}

#loader-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #eee;
  z-index: 10000 !important;
}

/* PAGETOP
---------------------------------------------------- */

#pagetopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 16px;
  height: 157px;
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  z-index: 8000;

  img {}
}

.scrollMax #pagetopBtn {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  #pagetopBtn {
    bottom: 10px;
    right: 4px;
    width: 16px;
    height: 157px;
  }
}
